<template>
  <div>
    <notifications width="470" />
    <h1 class="login">
      {{$t('login.passwordReset')}}
    </h1>
    <p class="login">
      {{$t('login.passwordRessetDesc')}}
    </p>
    <form @submit.prevent="sendLink">
      <div class="form-group has-feedback">
        <input
          v-model="email"
          type="text"
          class="form-control"
          placeholder="Email"
          name="email"
        >
      </div>
      <div
        v-if="response"
        class="callout callout-info"
      >
        <p>{{ response }}</p>
      </div>
      <button
        type="submit"
        :class="'zord-btn btn zord-btn-primary btn-primary btn-flat zord-btn-flat ' + (isLoading ? 'disabled' : '')"
        :disabled="isLoading"
      >
        {{$t('login.passwordResetSendLinkButton')}}
      </button>
    </form>
    <hr class="line-login">
    <router-link :to="{ name: 'security_login' }">
      {{$t('login.backToLogin')}}
    </router-link>
  </div>
</template>

<script>
import api from '../../../api'
import Loader from '../../share/Loader'

export default {
  mixins: [
    Loader
  ],
  data () {
    return {
      loading: '',
      email: '',
      response: ''
    }
  },
  methods: {
    sendLink () {
      const { email } = this
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')

      api.request('core', 'post', 'security/password-reset-link', { email })
        .then((response) => {
          this.toggleLoading()
          this.$notify({
            type: 'success',
            text: this.$t('login.passwordResetNotif')
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.$notify({
            type: 'error',
            text: error.response.data.error.message
          })
        })
    }
  }
}
</script>

<style scoped>
.vertical-10p {
    padding-top: 10%;
}

.logo-login {
    margin-bottom: 15px;
}

.line-login {
    border-color: #666;
}
</style>
